@import 'utils';

.pdf_main {
  margin: spacing(10) auto;
  max-width: 1440px;
  padding: 0 spacing(5);

  width: 100%;
  @include up(tablet) {
    padding: 0 spacing(15);
  }
}

.breadcrumb {
  @include up(desktop) {
    grid-area: 32px;
    margin-bottom: 0;
  }
}

.header {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: spacing(2);
  justify-content: space-between;
  padding-top: spacing(4);

  a {
    height: 42px;
    white-space: nowrap;
    border-width: 2px;
  }
}

.header_button_description {
  display: flex;
  gap: spacing(6);
  align-items: flex-start;
  border-top: 1px solid #e8e8e8;
  padding: spacing(5) spacing(6) 0 0;
}

.title {
  color: var(--color-product-text);
}

.pdf_main_viewer {
  display: flex;
  justify-content: center;
}

.pdf_main_iframe {
  align-items: center;
  display: flex;
  height: calc(90vh - 60px);
  margin-top: spacing(6);
  width: 100%;
}
